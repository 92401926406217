import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import Notifications from "@kyvg/vue3-notification";

var VueScrollTo = require("vue-scrollto");

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueScrollTo)
  .use(Notifications);

app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);
app.mount("#app");
