<template>
  <div
    class="min-h-screen p-8  bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
  >
    <div class="flex justify-center ">
      <div
        class="container my-auto max-w-md p-3 bg-white shadow-lg rounded-2xl"
      >
        <h3 class="cool-font text-center text-3xl my-6">
          RSVP
        </h3>
        <div
          id="card_open"
          class="flex flex-col xs:flex-row justify-evenly text-white"
        >
          <button
            class="block flex flex-row items-center px-4 py-2 rounded-md"
            style="background-color: #d4af37;"
            @click="
              tab = 'rekening';
              modalState();
            "
          >
            <CashIcon class="h-5 w-5 mr-2" /> Kirim Uang
          </button>
          <button
            class="block flex flex-row items-center px-4 py-2 rounded-md mt-4 xs:mt-0"
            style="background-color: #d4af37;"
            @click="
              tab = 'alamat';
              modalState();
            "
          >
            <GiftIcon class="h-5 w-5 mr-2" /> Kirim Kado
          </button>
        </div>
        <!-- header -->
        <div class="text-center my-6">
          <p class="text-gray-500 font-semibold text-xs">
            {{ banyak }} Orang Sudah Memberikan Respon nya
          </p>
          <!-- <p class="text-gray-500">Ayo Berikan Respon mu juga!</p> -->
        </div>
        <hr />

        <div class="m-6">
          <form class="mb-6">
            <div class="mb-4 flex flex-row items-center gap-2">
              <label
                for="avatar"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Pilih Avatar :
              </label>

              <div
                @click="rsvp.ava = 1"
                class="w-10 h-10 relative rounded-full overflow-hidden shadow-inner table cursor-pointer border-2 border-gray-400"
              >
                <div
                  class="relative w-full h-full bg-cover bg-no-repeat "
                  :class="rsvp.ava != 1 ? 'bg-gray-400 bg-blend-multiply' : ''"
                  style="background-image:url('/images/ava_1.png');"
                ></div>
              </div>

              <div
                @click="rsvp.ava = 2"
                class="w-10 h-10 relative rounded-full overflow-hidden shadow-inner table cursor-pointer border-2 border-gray-400"
              >
                <div
                  class="relative w-full h-full bg-cover bg-no-repeat "
                  :class="rsvp.ava != 2 ? 'bg-gray-400 bg-blend-multiply' : ''"
                  style="background-image:url('/images/ava_2.png');"
                ></div>
              </div>

              <div
                @click="rsvp.ava = 3"
                class="w-10 h-10 relative rounded-full overflow-hidden shadow-inner table cursor-pointer border-2 border-gray-400"
              >
                <div
                  class="relative w-full h-full bg-cover bg-no-repeat "
                  :class="rsvp.ava != 3 ? 'bg-gray-400 bg-blend-multiply' : ''"
                  style="background-image:url('/images/ava_3.png');"
                ></div>
              </div>

              <div
                @click="rsvp.ava = 4"
                class="w-10 h-10 relative rounded-full overflow-hidden shadow-inner table cursor-pointer border-2 border-gray-400"
              >
                <div
                  class="relative w-full h-full bg-cover bg-no-repeat "
                  :class="rsvp.ava != 4 ? 'bg-gray-400 bg-blend-multiply' : ''"
                  style="background-image:url('/images/ava_4.png');"
                ></div>
              </div>
            </div>
            <div class="mb-4">
              <label
                for="nama"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Nama Tamu</label
              >
              <input
                type="text"
                placeholder="Nama anda"
                v-model="rsvp.nama"
                class="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 "
              />
            </div>
            <div class="mb-4">
              <label
                for="status"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Status Kehadiran</label
              >
              <div class="relative">
                <div
                  @click="dropdownOpen = !dropdownOpen"
                  class="relative z-10 block rounded-md bg-white p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 w-full flex flex-row justify-between"
                >
                  <span v-if="rsvp.status == ''" class="text-gray-400"
                    >Status Kehadiran</span
                  >
                  <span v-else>{{ rsvp.status }}</span>
                  <svg
                    class="h-5 w-5 text-gray-800"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>

                <div
                  v-show="dropdownOpen"
                  @click="dropdownOpen = false"
                  class="fixed inset-0 h-full w-full z-10"
                ></div>

                <div
                  v-if="dropdownOpen"
                  class="absolute right-0 pb-2 w-full bg-white rounded-md shadow-xl z-20"
                >
                  <a
                    class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                    @click="
                      rsvp.status = 'InsyaALLAH Datang!';
                      dropdownOpen = false;
                    "
                  >
                    InsyaALLAH Datang!
                  </a>
                  <a
                    class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                    @click="
                      rsvp.status = 'Belum Tentu Bisa';
                      dropdownOpen = false;
                    "
                    >Belum Tentu Bisa
                  </a>
                  <a
                    class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                    @click="
                      rsvp.status = 'Maaf Tidak Bisa Hadir';
                      dropdownOpen = false;
                    "
                    >Maaf Tidak Bisa Hadir
                  </a>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label
                for="ucapan"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Ucapan</label
              >
              <textarea
                placeholder="Sampaikan Ucapan Anda"
                v-model="rsvp.ucapan"
                class="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 "
              ></textarea>
            </div>

            <div class="mb-6">
              <button
                type="button"
                @click="sendrespond"
                class="w-full px-3 py-4 text-white  rounded-md  focus:outline-none duration-100 ease-in-out"
                :class="
                  rsvp.nama == '' || rsvp.status == '' || rsvp.ucapan == ''
                    ? 'bg-biru-pudar cursor-not-allowed'
                    : 'bg-biru hover:bg-biru-kuat'
                "
              >
                Kirim Ucapan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class=" p-8 bg-white rounded-2xl shadow-lg mt-8">
      <h3 class="text-center text-3xl mb-8 cool-font">
        Ucapan
      </h3>

      <div
        class="grid grid-cols-12 gap-y-2 h-80 overflow-y-scroll bg-blue-100 rounded-2xl shadow-inner"
      >
        <div
          class="col-start-1 col-end-12 p-3 rounded-lg"
          v-for="(val, i) in ucapan"
          :key="i"
        >
          <div class="flex items-center flex-row">
            <div
              class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
            >
              <div
                class="relative w-full h-full bg-cover bg-no-repeat "
                :style="`background-image:url('/images/ava_${val.ava}.png');`"
              ></div>
            </div>
            <div
              class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl w-full"
            >
              <div class="text-xs text-gray-500">
                {{ val.nama }}
              </div>
              <div>
                {{ val.ucapan }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="card_panel"
    class="main-modal w-full fixed inset-0 z-20 overflow-hidden hidden bg-blend"
  >
    <div class="flex justify-center items-start">
      <div
        class="modal-container w-full bg-white mx-auto rounded-sm z-50 overflow-y-auto"
        style="max-width:425px"
      >
        <div class="modal-content " v-if="tab == 'rekening'">
          <div class="py-4 text-left px-6">
            <div class="flex justify-between items-center pb-3">
              <p></p>
              <p class="text-2xl text-center font-bold cool-font">Rekening</p>
              <div
                id="card_close"
                class="modal-close cursor-pointer z-50"
                @click="modalState"
              >
                <svg
                  class="fill-current text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="my-5 mr-5 ml-5 flex flex-col justify-center">
              <div class="flex flex-row justify-evenly mb-4">
                <img src="/images/qr_bca.png" />
              </div>
              <table>
                <tr>
                  <td>Bank</td>
                  <td>:</td>
                  <td>BCA (Kode : 014)</td>
                </tr>
                <tr>
                  <td>No. Rekening</td>
                  <td>:</td>
                  <td>3451962963</td>
                </tr>
                <tr>
                  <td>A/N</td>
                  <td>:</td>
                  <td>FERRY NOVRIANDI</td>
                </tr>
              </table>
            </div>
          </div>
          <button
            type="button"
            @click="copyRek"
            class="w-full px-3 py-3 text-white bg-biru hover:bg-biru-kuat focus:outline-none duration-100 ease-in-out"
          >
            Salin Rekening
          </button>
        </div>
        <div class="modal-content " v-if="tab == 'alamat'">
          <div class="py-4 text-left px-6">
            <div class="flex justify-between items-center pb-3">
              <p></p>
              <p class="text-2xl text-center font-bold cool-font">Alamat</p>
              <div
                id="card_close"
                class="modal-close cursor-pointer z-50"
                @click="modalState"
              >
                <XIcon class="w-7 h-7"></XIcon>
              </div>
            </div>
            <div class="my-5 mr-5 ml-5 flex flex-col justify-center">
              <table>
                <tr>
                  <td>Penerima</td>
                  <td class="text-center">:</td>
                  <td>FERRY NOVRIANDI</td>
                </tr>
                <tr>
                  <td>Kontak</td>
                  <td class="text-center">:</td>
                  <td>0812-6728-0919</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td class="text-center">:</td>
                  <td>
                    Suvarna Sutera Jati, Cluster Bayu, Jalan Bayu 12 No. 3,
                    Wanakerta, Sindang Jaya, Kab. Tangerang
                  </td>
                </tr>
              </table>

              <p></p>
            </div>
          </div>
          <button
            type="button"
            @click="copyAdr"
            class="w-full px-3 py-3 text-white bg-biru hover:bg-biru-kuat focus:outline-none duration-100 ease-in-out"
          >
            Salin Alamat
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, inject, onMounted, ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
window.onclick = function(event) {
  if (event.target.id == "card_panel") {
    const card_open = document.getElementById("card_open");
    const card_panel = document.getElementById("card_panel");

    card_panel.classList.add("hidden");
    card_panel.classList.remove("block");

    // Show button
    card_open.classList.remove("hidden");
    card_open.classList.add("block");

    // Remove animation open
    card_panel.classList.remove("card_open");
  }
};

import { CashIcon, GiftIcon, XIcon } from "@heroicons/vue/solid";
export default {
  components: { CashIcon, GiftIcon, XIcon },
  mounted() {
    this.sendrespond();
  },
  setup() {
    const $http = inject("$http");
    let rsvp = reactive({
      ava: 1,
      nama: "",
      status: "",
      ucapan: "",
    });

    let ucapan = ref([]);
    let banyak = ref(0);

    let sendrespond = async () => {
      if (rsvp.nama == "" || rsvp.status == "" || rsvp.ucapan == "") return;
      await $http.post(`/rsvp/add`, rsvp);

      rsvp.ava = 1;
      rsvp.nama = "";
      rsvp.status = "";
      rsvp.ucapan = "";
      await getlen();
      await getChat();
    };

    let getlen = async () => {
      let resp = await $http.get(`/rsvp/getlen`);
      banyak.value = resp.data.banyak;
    };

    let getChat = async () => {
      let resp = await $http.get(`/rsvp/ucapan`);
      ucapan.value = resp.data;
    };

    let copyRek = () => {
      let rek = "3451962963";
      navigator.clipboard.writeText(rek);
      notify({
        text: "Rekening Telah Disalin",
      });
    };

    let copyAdr = () => {
      let adr =
        "Suvarna Sutera Jati, Cluster Bayu, Jalan Bayu 12 No. 3, Wanakerta, Sindang Jaya, Kab. Tangerang";
      navigator.clipboard.writeText(adr);
      notify({
        text: "Alamat Telah Disalin",
      });
    };

    function modalState() {
      const card_open = document.getElementById("card_open");
      const card_panel = document.getElementById("card_panel");

      if (card_panel.classList.contains("hidden")) {
        // Show modal
        card_panel.classList.remove("hidden");
        card_panel.classList.add("block");

        // Start animation open
        card_panel.classList.add("card_open");
      } else {
        // Delete modal
        card_panel.classList.add("hidden");
        card_panel.classList.remove("block");

        // Remove animation open
        card_panel.classList.remove("card_open");
      }
    }

    onMounted(async () => {
      await getlen();
      await getChat();
    });

    return {
      dropdownOpen: ref(false),
      rsvp,
      sendrespond,
      ucapan,
      banyak,
      modalState,
      tab: ref(""),
      copyRek,
      copyAdr,
    };
  },
};
</script>
<style scoped>
.bg-blend {
  background-color: rgba(75, 75, 75, 0.5);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

:root {
  --shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --ring-offset-shadow: 0 0 #0000;
  --ring-shadow: 0 0 #0000;
}

.card_open {
  animation-duration: 2s;
  animation-name: card_open;
}

@keyframes card_open {
  from {
    height: 1%;
    box-shadow: var(--shadow);
  }

  to {
    height: 100%;
    box-shadow: var(--ring-offset-shadow, 0 0 #0000),
      var(--ring-shadow, 0 0 #0000), var(--shadow);
  }
}

td {
  min-width: 20px;
  vertical-align: top;
}
</style>
