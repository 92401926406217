<template>
  <div
    class="min-h-screen bg-white p-4 py-8 pt-16 text-center text-xs  "
    style="line-height: 1.5;"
  >
    <img src="/images/cover_5.png" alt="" />
    <button
      class="mt-36 bg-blue-900 text-blue-100  font-bold text-lg border-blue-800   border-2 px-4 py-2 rounded-2xl"
      v-if="!isOpen"
      @click="
        isOpen = true;
        $emit('open');
      "
    >
      Buka Undangan
    </button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    return {
      isOpen: ref(false),
    };
  },
};
</script>
