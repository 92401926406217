<template>
  <div class="mx-auto bg-yellow-500" style="max-width:425px; ">
    <cover
      class="snap-scroll border-b-4 border-blue-100  "
      style="background-color: #16293A;"
      @open="
        isOpen = true;
        onAudio();
      "
      v-scroll-to="'#mempelai'"
    ></cover>
    <div v-if="isOpen">
      <mempelai
        id="mempelai"
        class="snap-scroll bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
      ></mempelai>
      <acara
        class="snap-scroll bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
      ></acara>
      <lokasi
        class="snap-scroll  bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
      ></lokasi>
      <ucapan
        class="snap-scroll  bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
      ></ucapan>
      <prokes
        class="snap-scroll  bg-flower border-b-4 border-blue-100 bg-gray-400 bg-blend-screen"
      ></prokes>
      <div
        class="p-4 flex flex-col items-center"
        style="background-color: #6c7d86;"
      >
        <a class="text-center text-white">Kreasikan undangan virtualmu di</a>
        <a class="text-white" href="http://software.ligno.co.id/#/"
          >www.software.ligno.co.id</a
        >
      </div>
      <div
        v-if="isOpen && showTriangle"
        class="fixed bottom-6 w-screen left-1/2 translate-x-1/2"
      >
        <a class="arrow down"></a>
      </div>
      <button
        class="fixed bottom-8 right-8 bg-pink-600 rounded-full z-20 shadow p-2"
        @click="playMusic"
      >
        <svg
          v-if="data.isOn"
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 fill-current text-blue-100"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 fill-current text-blue-100"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Cover from "@/components/Cover";
import Acara from "@/components/Acara";
import Mempelai from "@/components/Mempelai";
import Lokasi from "@/components/Lokasi";
import Ucapan from "@/components/Ucapan";
import Prokes from "@/components/Prokes";
import { ref, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    Cover,
    Acara,
    Mempelai,
    Lokasi,
    Ucapan,
    Prokes,
  },
  setup() {
    let isOpen = ref(false);
    const root = ref(null);
    let route = useRoute();

    let showTriangle = ref(true);

    onMounted(() => {
      window.addEventListener("scroll", logScroll);
    });

    let logScroll = () => {
      let bottomOfWindow =
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) +
          window.innerHeight ===
        document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        showTriangle.value = false;
      } else {
        showTriangle.value = true;
      }
    };

    const data = reactive({
      isOn: false,
      show_cover: true,
      isReady: false,
    });

    let audio = new Audio(require("@/assets/music/bg_2.mp3"));
    audio.preload = true;
    audio.loop = true;

    let playMusic = () => {
      console.log("playMusic");
      if (!data.isReady) {
        onAudio();
      }
      data.isOn = !data.isOn;
      audio.muted = data.isOn;
    };
    let stopMusic = () => {
      audio.stop();
    };

    let onAudio = async () => {
      data.isReady = true;
      await audio.play();
    };

    return {
      isOpen,
      playMusic,
      stopMusic,
      data,
      onAudio,
      root,
      showTriangle,
    };
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}

.arrow {
  border-color: transparent;
  border-style: solid;
  border-width: 0 1.5em;
  display: block;
  opacity: 0.4;
  text-indent: -9999px;
  width: 0;
}

.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
  border-top: 2em solid #333333;
}
</style>
