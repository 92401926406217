<template>
  <div class="min-h-screen p-4 py-9 text-center">
    <div class=" p-4">
      <div class="cool-font text-3xl mb-8">Lokasi</div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1945543560364!2d106.66516041529529!3d-6.238068762824067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fbe75038aba3%3A0xec24c7a80eba26d3!2sGedung%20Graha%20Bhara%20Daksa!5e0!3m2!1sen!2sid!4v1634634828049!5m2!1sen!2sid"
        style="border:0;"
        width="100%"
        height="310px"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
