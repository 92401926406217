<template>
  <div class="min-h-screen">
    <h3 class="cool-font text-center text-3xl pt-12">
      Prokes
    </h3>
    <div class="flex flex-row justify-evenly">
      <img src="/images/prokes.png" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
