<template>
  <div
    class="min-h-screen bg-white p-4  py-8 text-center text-xs  "
    style="line-height: 1.5;"
  >
    <div>
      <img src="/images/bismillah_1.png" width="260px" class="mx-auto" />
    </div>
    <div class="mt-2">
      "Dan diantara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu
      pasangan hidup dari jenismu sendiri, supaya kamu mendapat ketenangan hati,
      dan dijadikan-Nya di antaramu rasa kasih dan sayang. Sesungguhnya yang
      demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir."
      <br />
      (Qs. Ar-Ruum : 21)
    </div>
    <div class="font-bold mt-12">
      Assalamualaikum Warahmatullahi Wabarakatuh
    </div>
    <div class="mt-2">
      Dengan penuh rasa syukur dan memohon <br />
      kalimat Rahmat serta Ridho Allah SWT, <br />
      Kami bermaksud menyelenggarakan syukuran atas <br />
      pernikahan putra-putri kami :
    </div>
    <div class="mt-8">
      <div class="cool-font text-2xl ">
        Maharani Afiina Yolanda
      </div>
      <div class="mt-1">Putri Pertama dari</div>
      <div>Bapak Supriyono (Alm) dan Ibu Raviko Furdila</div>
    </div>
    <div class="mt-8">
      <div class="cool-font text-2xl">Ferry Novriandi</div>
      <div class="mt-1">Putra Pertama dari</div>
      <div>Bapak Syamsuardi dan Ibu Rahmadani</div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
