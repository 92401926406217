<template>
  <div
    class="min-h-screen bg-white p-4 py-8 text-center text-xs "
    style="line-height: 1.5;"
  >
    <div>
      Yang insya Allah akan diselenggarakan pada :
    </div>
    <div
      class="mt-4 rounded-lg h-28 w-28 bg-biru text-white font-bold  mx-auto flex flex-col items-center justify-center shadow-lg"
    >
      <div>Minggu</div>
      <div class="text-3xl">7</div>
      <div>November</div>
      <div>2021</div>
    </div>
    <div class="mt-4">
      <div class="cool-font text-3xl ">Akad Nikah</div>
      <div class="mt-1">09:00 WIB s.d Selesai</div>
    </div>
    <div class="mt-4">
      <div class="cool-font text-3xl">Resepsi</div>
      <div class="mt-1">
        <input
          type="checkbox"
          class="mr-2"
          :checked="$route.params.sesi == 1"
        />Sesi 1, 11:30 - 12:30 WIB
      </div>
      <div>
        <input
          type="checkbox"
          class="mr-2"
          :checked="$route.params.sesi == 2"
        />Sesi 2, 12:30 - 13:30 WIB
      </div>
      <div>
        <input
          type="checkbox"
          class="mr-2"
          :checked="$route.params.sesi == 3"
        />Sesi 3, 13:30 - 14:30 WIB
      </div>
      <div>
        <input
          type="checkbox"
          class="mr-2"
          :checked="$route.params.sesi == 4"
        />Sesi 4, 14:30 - 15:30 WIB
      </div>
    </div>
    <div class="mt-4">
      <div class="font-bold">
        Gedung Graha Bhara Daksa Pusdiklantas Polri
      </div>
      <div class="">
        Jl. Bhayangkara No.01 Paku Jaya, Kota Tangerang Selatan
      </div>
    </div>
    <div class="mt-8">
      Atas kehadiran dan do'a restunya kami ucapkan Terima Kasih
    </div>
    <div class="font-bold">
      Wassalamu'alaikum Warahmatullahi Wabarakatuh
    </div>
    <div class="mt-8">Kami yang berbahagia</div>

    <div class="mt-4 font-semibold flex justify-center space-x-12">
      <div class="">
        <div class="font-normal">Keluarga</div>
        <div>Bapak Supriyono (Alm)</div>
        <div>dan Ibu Raviko Furdila</div>
      </div>

      <div class="">
        <div class="font-normal">Keluarga</div>
        <div>Bapak Syamsuardi</div>
        <div>dan Ibu Rahmadani</div>
      </div>
    </div>

    <p class="cool-font text-3xl mt-12">
      Yoan & Ferry
    </p>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    let data = reactive({
      sesion: [
        "Sesi 1, 11:30 - 12:30 WIB",
        "Sesi 2, 12:30 - 13:30 WIB",
        "Sesi 3, 13:30 - 14:30 WIB",
        "Sesi 4, 14:30 - 15:30 WIB",
      ],
      key: 1,
    });
    onMounted(() => {
      if (route.params.id && route.params.id > 0 && route.params.id < 5) {
        data.key = parseInt(route.params.id);
      }
    });
    return {
      data,
    };
  },
};
</script>
<style lang="scss" scoped></style>
