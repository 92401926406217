<template>
  <notifications position="bottom center"></notifications>
  <router-view />
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss">
html {
  background-color: grey;
  // scroll-snap-type: y mandatory;
}
.snap-scroll {
  // scroll-snap-align: start;
}
.bg-flower {
  background-image: url("/images/bg-3.png") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}
@font-face {
  font-family: customfont;
  src: url("/fonts/Daylove.ttf");
}

.cool-font {
  font-family: "customfont";
  color: #d4af37;
}

.bg-biru {
  background-color: #5670a4;
}
.bg-biru-kuat {
  background-color: #3f5683;
}
.bg-biru-pudar {
  background-color: #9aaed6;
}
.vue-notification {
  background-color: #5670a4;
}
</style>
